body {
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

span,
div,
p,
h1,
h2,
h3,
h4,
input,
li,
label {
  font-family: Poppins;
  margin: 0;
}
a,
button {
  font-family: Poppins;
  text-decoration: none;
}

html,
body,
#root {
  display: flex;
  flex-direction: column;
  min-height: 100svh;
}
